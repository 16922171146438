//React
import * as React from 'react';

//Gatsby
import { graphql, Link } from 'gatsby';

//Styles
import * as styles from './my-work.module.scss';

//Components
import Layout from '../../components//Layout//Layout';
import Card from '../../components/Card/Card';
import PageContainer from '../../components/PageContainer/PageContainer';
import Seo from '../../components/seo';


//Images
import burgerBuilderLogo from '../../images/burger-builder-logo.png';
import mkrenovationsLogo from '../../images/mkr-official-logo-stylized.png';
import mtCleverestLogo from '../../images/mtcleverest-logo.png';
import chrisOneEightyLogo from '../../images/chris-180-logo.png';
import spaceALogo from '../../images/space-a-logo.png';
import nattyPetShopLogo from '../../images/natty-pet-shop-logo.png';
import superAutoPetsLogo from '../../images/super-auto-pets-logo.png';
import pdfResume from '../../images/Resume (August 2022).pdf';


const PAGE_NAMES = [
    'mkrenovations',
    'superautopets',
    'burgerbuilder',
    'spacea',
    'mtcleverest',
    'chris180',
    'nattypetshop'
  ]

const Work = ({ location, data }) => {
    const paths = {}
    PAGE_NAMES.forEach((path) => {
        return paths[path] = `/my-work/${path}`;
    })

    const projectData = [
        {
            title: 'MK Renovations',
            description: 'A website for a small construction company featuring email form submission with firebase cloud functions and node mailer',
            link: paths.mkrenovations,
            src: mkrenovationsLogo,
            alt: 'MK Renovations',
            bannerColor: '#194458'
        },
        {
            title: 'Super Auto Pets',
            description: "Redesign of an indie game's promotional site focussed on matching the in-game visual style",
            link: paths.superautopets,
            src: superAutoPetsLogo,
            alt: 'Super Auto Pets',
            bannerColor: '#8f6c0a'
        },
        {
            title: 'Burger Builder',
            description: 'A simple food order app with Firebase authentication',
            link: paths.burgerbuilder,
            src: burgerBuilderLogo,
            alt: 'Burger Builder',
            bannerColor: '#a75a27'
        },
        {
            title: 'Chris 180',
            description: "Usability audit of a non-profit's website",
            link: paths.chris180,
            src: chrisOneEightyLogo,
            alt: 'Chris 180',
            bannerColor: '#853535'
        },
        {
            title: 'Space-A',
            description: "Design for a mobile app to help military service members take advantage of an underutilized benefit",
            link: paths.spacea,
            src: spaceALogo,
            alt: 'Space-A',
            bannerColor: '#134f77'
        },
        {
            title: 'Mt. Cleverest',
            description: "Redesign of an ed tech startup's website focused on usability and onboarding",
            link: paths.mtcleverest,
            src: mtCleverestLogo,
            alt: 'Mt. Cleverest',
            bannerColor: '#433f6e'
        },
        {
            title: 'Natty Pet Shop',
            description: 'Squarespace site with custom code for a local pet shop',
            link: paths.nattypetshop,
            src: nattyPetShopLogo,
            alt: 'Natty Pet Shop',
            bannerColor: '#23501d'
        },
    ]

    const projectElements = projectData.map((project)=>{
        return(
            <div key={`${project.title} Card`} className={styles.gridItem} style={{animationDelay: `${0.3 + (projectData.indexOf(project)/projectData.length)}s`}}>
                <Card addedClasses={[styles.projectCard]}>
                    <Link to={project.link}>
                    <h2 style={{backgroundColor: `${project.bannerColor}`}}>{project.title}</h2> 
                    <img src={project.src} alt={project.alt} />
                    <div className={styles.divider}></div>
                    <p>{project.description}</p>
                    </Link>
                </Card>
            </div>
        )
    })

    return (
        <Layout location={location}>
            <Seo
                title={"My Work"}
                description={"Check out some of Dan Kolonay's projects and learn about his work history."}
            />
            <main id='work-page'>
                <PageContainer>
            
                    <h1 className={styles.title}>My Work</h1>
                    <h2 className={styles.subtitle}>Check out some of my projects and learn about my work history</h2>
                    <div className={styles.workGrid}>
                       {projectElements}
                    </div>
                    <Card addedClasses={[styles.resumeCard]}>
                        <h2>Resume</h2>
                        <h3>Experience</h3>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>MK Renovations — Web Developer</p>
                            <p className={styles.resumeItalic}>Spring 2022</p>
                            <ul>
                                <li>Developed an 8-page responsive website using React</li>
                                <li>Designed a submission form that accepts text an images using Node Mailer</li>
                                <li>Optimized the site for SEO with Gatsby and React Helmet</li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Chris 180 — Usability Auditor</p>
                            <p className={styles.resumeItalic}>Spring 2020</p>
                            <ul>
                                <li>Analyzed ~60 pages of chris180.org to identify usability issues based on best practices</li>
                                <li>Wrote a report with screenshot examples to detail issues with the site and provide actionable feedback</li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Natty Pet Shop — SquareSpace Website Designer</p>
                            <p className={styles.resumeItalic}>Spring 2020</p>
                            <ul>
                                <li>Designed graphics and logos for Natty Pet Shop's website and business cards</li>
                                <li>Created a website with SquareSpace to host Natty Pet Shop’s online store</li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Mt. Cleverest — UX Consultant</p>
                            <p className={styles.resumeItalic}>Summer - Fall 2018</p>
                            <ul>
                                <li>  Conducted interviews, comparative analysis, and heuristic analysis to understand the site's current problem points </li>
                                <li>  Drafted wireframes, mockups, and prototypes to validate research </li>
                                <li>  Wrote research reports and drafted user flows and site map </li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Wag Labs inc. — Independent Contractor</p>
                            <p className={styles.resumeItalic}>Summer 2017 - Present</p>
                            <ul>
                                <li>Provided walks and boarding for over 1500 clients' pets</li>
                                <li>Built a clientele of ~10 private clients in addition to Wag clients</li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Federal Reserve — Currency Education Program Intern</p>
                            <p className={styles.resumeItalic}>Fall - Winter 2016 </p>
                            <ul>
                                <li>Designed and analyzed usability tests for uscurrency.gov</li>
                                <li>  Responded to questions from a public comments page </li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>Centronía — Graphic Design Intern	</p>
                            <p className={styles.resumeItalic}>Fall - Winter 2015 </p>
                            <ul>
                                <li> Designed Spanish and English pamphlets for a school blog </li>
                            </ul>
                        </div>
                        <h3>Education</h3>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>General Assembly — New York, NY </p>
                            <ul>
                                <li> User Experience Design Immersive Student </li>
                            </ul>
                        </div>
                        <div className={styles.resumeItem}>
                            <p className={styles.resumeBold}>George Washington University — Washington, DC </p>
                            <ul>
                                <li>B.A. in Economics </li>
                                <li>B.A. in Spanish & L.A. Languages, Literatures, and Cultures </li>
                                <li>Minor in Psychology </li>
                                <li>Graduated May 2017 [3.6 GPA] </li>
                            </ul>
                            <a href={pdfResume} target='_blank' rel="noopener noreferrer">View the pdf version</a>
                        </div>
                    </Card>
                </PageContainer>

            </main>
        </Layout>
    )
}

export const pageQuery = graphql`
query workPagesQuery {
    allMdx {
      edges {
        node {
          id
          slug
        }
      }
    }
  }  
`

export default Work;


// extracted by mini-css-extract-plugin
export var divider = "my-work-module--divider--d205c";
export var gridItem = "my-work-module--gridItem--430fe";
export var popIn = "my-work-module--popIn--d1a27";
export var projectCard = "my-work-module--projectCard--13859";
export var resumeBold = "my-work-module--resumeBold--692a8";
export var resumeCard = "my-work-module--resumeCard--fb67e";
export var resumeItalic = "my-work-module--resumeItalic--177b0";
export var resumeItem = "my-work-module--resumeItem--861a5";
export var subtitle = "my-work-module--subtitle--db26a";
export var title = "my-work-module--title--71499";
export var titleCard = "my-work-module--titleCard--5aaf5";
export var workGrid = "my-work-module--workGrid--843bd";